import axios from "axios";

const apiHost = process.env.VUE_APP_URL || "/";

let baseURL = "api";

if (apiHost) {
  baseURL = `${apiHost}`;
}

export default axios.create({ baseURL: baseURL });

// /* Global axios */
// import HTTP from "./AxiosIndex";

// // Add a response interceptor
// HTTP.interceptors.response.use(
//   response => {
//     if (response.data instanceof Blob) {
//       return response.data;
//     }
//     return response.data || {};
//   },
//   error => {
//     return Promise.reject(error);
//   }
// );

//Please do not delete