<template>
    <div id="app">
        <ar-master></ar-master>
    </div>
</template>

<script>
import Master from './components/layouts/Master'
export default {
    
  name: 'app',
  components:{
      'ar-master':Master
  },
  mounted(){
    let hours = 1
    let now   = new Date().getTime()
    if((now - localStorage.getItem("storage_started")) > hours * 60 * 60 * 1000){
    //   let confirm = confirm("Stay logged in?")
    //   if(!confirm){
    //     localStorage.clear()
    //   }
    }

  },
  watch:{
    '$route'(to,from){
      document.title = to.meta.title || "Artemis"
    }
  }
}
</script>

<style>
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    *::-webkit-scrollbar-track {
        background-color: #f1f1f1;
    }

    *::-webkit-scrollbar {
        -webkit-transition: .2s ease;
        transition: .2s ease;
        background-color: #a1a2aa;
        width: 6px;
    }

    *::-webkit-scrollbar-thumb {
        background-color: #c5c5c5;
        border-radius: 6px;
    }

    *::-webkit-scrollbar-thumb:hover {
         background-color: #a1a2aa;
    }

    .remove-date-spinner::-webkit-inner-spin-button {
        display:none;
        -webkit-appearance: none;
    }

    body {
        font-family: 'Roboto', Arial, Helvetica, sans-serif;
        text-rendering: optimizeLegibility;
        -webkit-font-smoothing: antialiased;
        font-size: 14px;
        line-height: 1.4;
    }

    .dropdown-item {
        font-size: 14px;
    }

    .line-clamp {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .color-for-rework {
        color: #ADBBD8;
    }

    .color-wip {
        color: #3E88DA;
    }

    .color-royal-blue {
        color: #2D599E;
    }

    .font-weight-medium {
        font-weight: 500 !important;
    }

    /*
    *   CHECKBOX AND RADIO GLOBAL VARIATION
    */
    .custom-control-label::after {
        background: no-repeat 50% / 60% 60%;
    }

    .custom-control-input ~ .custom-control-label::before {
        border: 1px solid transparent;
        background-color: #ffffff;
        box-shadow: 0 0 0;
    }

    .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
        top: 0.27rem;
        left: -2.2rem;
        width: 0.76rem;
        height: 0.76rem;
        border-radius: 100%;
        background-image: none;
    }

    .custom-radio .custom-control-input ~ .custom-control-label::before {
        top: -0.05rem;
        width: 1.35rem;
        height: 1.36rem;
    }

    .custom-control.custom-radio.sm {
        padding-left: 0.9rem;
    }

    .custom-radio.sm .custom-control-input:checked ~ .custom-control-label::after {
        /* top: 0.23rem; */
        left: -0.1rem;
        width: 0.56rem;
        height: 0.56rem;
        border-radius: 100%;
        background-image: none;
    }

    .custom-radio.sm .custom-control-input ~ .custom-control-label::before {
        top: 0.05rem;
        left: -0.3rem;
        width: 0.95rem;
        height: 0.95rem;
    }

    .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
        background-color: #ffff !important;
    }

    .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
        top: 0;
        width: 1.35rem;
        height: 1.35rem;
    }

    .custom-checkbox .custom-control-input ~ .custom-control-label::before {
        top: 0;
        width: 1.35rem;
        height: 1.35rem;
    }

    .custom-control.custom-checkbox.sm {
        padding-left: 0.9rem;
    }

    .custom-checkbox.sm .custom-control-label::after {
        top: 0.11rem;
        left: -0.75rem;
        width: 1.06rem;
        height: 1.06rem;
        border-radius: 100%;
        background-image: none;
    }

    .custom-checkbox.sm .custom-control-input:checked ~ .custom-control-label::after {
        top: 0.11rem;
        left: -0.75rem;
        width: 1.06rem;
        height: 1.06rem;
        border-radius: 100%;
    }

    .custom-checkbox.sm .custom-control-input ~ .custom-control-label::before {
        top: 0.15rem;
        left: -0.7rem;
        width: 0.95rem;
        height: 0.95rem;
    }

    .fieldset-alt {
        border: 1px solid #ccc;
        padding: 6px 10px 10px;
        border-radius: 4px;
    }

    .legend-alt {
        display: inline-block;
        position: relative;
        background: #ffff;
        padding: 0 10px;
        top: -23px;
    }

    /*
    * VER VARIATION
    */
    .gui-ver .custom-control-input ~ .custom-control-label::before {
        border-color: #3e88da;
    }

    .gui-ver .custom-control-input:checked ~ .custom-control-label::before {
        border-color: #3e88da;
        background-color: #3e88da;
    }

    .gui-ver .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
        background-color: #3e88da;
    }

    /*
    * CM VARIATION
    */
    .gui-cm .custom-control-input ~ .custom-control-label::before {
        border-color: #3b5998;
    }

    .gui-cm .custom-control-input:checked ~ .custom-control-label::before {
        border-color: #3b5998;
        background-color: #3b5998;
    }

    .gui-cm .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
        background-color: #3b5998;
    }

    /*
    * ADMIN VARIATION
    */
    .gui-admin .custom-control-input ~ .custom-control-label::before {
        border-color: #480048;
    }

    .gui-admin .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
        border-color: #480048;
        background-color: #663366;
    }

    .gui-admin .custom-control-input:checked ~ .custom-control-label::before {
        border-color: #480048;
        background-color: #480048;
    }

    .gui-admin .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
        background-color: #480048;
    }

    /* Media queries */
    @media screen and (max-width: 1120px) {
        input[placeholder="Search RID here"] {
            width: 200px !important;
        }
    }
</style>
